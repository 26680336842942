* {
    background-color:#0A192F;
    overflow-x: hidden;
}

.fade-in {
    opacity: 0;
    animation: fade-in 1s forwards;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  