.Hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 25px;
    height: 25px;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  
  .Hamburger-line {
    width: 100%;
    height: 0.25rem;
    background-color: rgb(100, 255, 218);
  }

  .Hamburger :focus {
    outline: none;
  }
  .Hamburger-line:focus {
    transition: none !important;
  }
 
  @media only screen and (max-width: 768px) {
        .Hamburger{
            display: flex;
        }
  }