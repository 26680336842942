.ContentDiv {
    margin: 0px auto;
    width: 100%;
    padding-left: 180px;
    padding-right: 150px;
    box-sizing: border-box;
}

@media only screen and (max-width: 1366px) {
    .ContentDiv {
       width: 80%;
       margin: 0px auto;
       padding: 0;
       display: flex;
       align-items: center;
       justify-content: center;
       flex-direction: column;
    }
  }


  /* @media only screen and (max-width: 1024px) {
    .ContentDiv {
       width: 100%;
       margin: 0px auto;
       padding: 0;
       display: flex;
       align-items: center;
       justify-content: center;
       flex-direction: column;
    }
  } */
