.DescriptionContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 90%;
    margin: auto;
    height: 80vh;
}

.big-heading{
    font-size: clamp(10px,20px,25px);
}
.Description {
    font-size: clamp(20px,36px,60px);
    margin: 0;
}

h1 {
    display: block;
    color: #64ffda;
    font-family: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;
    font-weight: 400;
    padding-bottom: 10px;
    margin:0px;
}

h2 {
    display: block;
    margin: 0px;
    font-weight: bold;
    color: #e4f8f3;
    padding-bottom: 30px;
}

h3 {
    display: block;
    margin: 0px;
    font-weight: bold;
    color: #8892B0;
}

.DescriptionParagraph {
    font-size: clamp(10px,28px,25px);
    color: #8892b0;
    margin: 20px 0px 0px;
    max-width: 750px;
}
 .DescriptionParagraph > a {
    text-decoration: none;
    color: #64ffda;
 }

 .DescriptionImg {
    width: 600px;
    height: 400px;
 }
 
 @keyframes fade-bottom {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
 @media only screen and (max-width: 768px) {
    .big-heading{
        justify-content: center;
        flex-direction: column;
        margin: 0px;
    }
    .Description {
        font-size: clamp(20px,15px,60px);
        margin: 0;
    }

    h1 {
        font-size: clamp(20px,25px,60px);
    }
   
    
  }
  @media only screen and (max-width: 1366px) {
    .DescriptionImg {
        display: none;
     }

     .Description {
        font-size: clamp(20px,30px,60px);
        margin: 0;
    }
     .DescriptionContainer {
        height: auto;
       }
    
}
@media only screen and (max-width: 1555px) {
   
     .DescriptionContainer{
        width: 100%;
     }
    
}
