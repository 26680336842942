.Footer {
    margin-top: 50px;
    display:flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.Footer > p {
    color: #8892B0;
}

.Footer > p:hover {
    color: #64ffda;
  }