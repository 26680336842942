*{
  box-sizing: border-box;
}

body {
  margin: 0;
}

.logo {
  height: 80px;
  width: 80px;
  overflow: hidden;
}

.nav {
  background-color: #0A192F;
  position:relative;
  z-index: 1;
  color: white;
  display: flex;
  justify-content:space-between;
  gap: 20px;
  padding: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

.site-title {
  font-size: 13px;
}

.nav ol {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 30px;
}

.nav a {
  color: #CCD6f6;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 5px;
  font-size: clamp(10px, 20px, 25px);
}
.nav li {
  color: #64ffda;
  counter-increment: item 1;
}
.nav a:hover{
  color: #64ffda;
}

.nav ol li a::before {
  content: "0" counter(item) ".";
  margin-right: 5px;
  color: #64ffda;
  text-align: right;
}

.ResumeButton:hover{
  background-color:rgb(100, 255, 218, 0.1);
}

.LinksAndButtonDiv{
  display: flex;
  align-items: center;
  margin: 0;
}

.ResumeButton{
  height: 40px;
  width: 90px;
  color: #64ffda;
  background-color: transparent;
  border: 1px solid #64ffda;
  border-radius: 4px;
  padding: 10px 15px;
  font-family: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);;
  font-size: 15px;
}



@keyframes animate {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media only screen and (max-width: 1366px) {
  .nav {
    margin-left: 10%;
    margin-right: 10%;
    padding: 30px 0 30px 0;
  }
}

@media only screen and (max-width: 768px) {
  .nav {
    display: none;
  }
  .logo{
    overflow: hidden;
  }
  
  .HamburgerNav {
    display: flex;
    width: 80%;
    margin: 30px auto 30px auto;
  }

  .Menu {
    display: grid;
  }

  .svgButton {
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 50px;
    height: 50px;
  }

  .MenuHeader {
    display: flex;
    width: 80%;
    margin: 50px auto 30px auto;
  }
}