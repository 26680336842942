.Button{
    color: #64ffda;
    background-color: transparent;
    border: 1px solid #64ffda;
    border-radius: 4px;
    padding: 10px 15px;
    font-family: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);;
    font-size: 15px;
    pointer-events: none;
    
  }

  .Button:hover{
    background-color:rgb(100, 255, 218, 0.1);
  }