#contact {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.availability {
    font-size: clamp(10px, 20px, 25px);
    max-width: 1000px;
    color: #ccd6f6a7;
    font-family: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;;
}
