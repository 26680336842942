#Experience {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

}
.ContainerContent {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1000px;
}

.ButtonContent {
    display: flex;
    flex-direction: column;
    min-width: 150px;
}
.Content{
    color:aquamarine;
    height: auto;
    padding: 10px 5px;
}

.Info {
    padding-left:20px;
}

.ButtonContainer {
    display: flex;
    flex-direction: column;
  }

.SelectButton {
    transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
    display: flex;
    align-items: center;
    width: 100%;
    height: 42px;
    padding: 0px 10px 2px;
    background-color: transparent;
    color:  #64ffda;;
    font-family: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;;
    font-size: 13px;
    margin-bottom: 10px;
    white-space: nowrap;
}

.UnselectedButton {
    transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
    display: flex;
    align-items:center;
    width: 100%;
    height: 42px;
    padding: 0px 10px 2px;
    background-color: transparent;
    color:  #ccd6f6;
    font-family: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;;
    font-size: 13px;
    white-space: nowrap;
}

.H3Header {
    color:#ccd6f6;
    font-size: 25px;
}

.CompanyHeader {
    color: #64ffda;
    text-decoration: none;
}

.TimeLine {
    color: #a8b2d1;
    margin-bottom: 30px;
    font-size: clamp(10px, 20px, 25px);
}

.ListOfResponsibilities {
    gap: 0px 10px;
    padding: 0px;
    margin: 20px 0px 0px;
    overflow: hidden;
    list-style: none;
}
.ListOfResponsibilities > li {
    position: relative;
    margin-bottom: 10px;
    padding: 0px;
    padding-left: 20px;
    font-family: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;
    font-size: clamp(10px, 20px, 25px);
    color: #8892B0;
}

.ListOfResponsibilities > li::before {
    content: "▹";
    position: absolute;
    left: 0px;
    top: 5px;
    color: #64ffda;
    font-size: clamp(10px, 18px, 25px);
    line-height: 12px;
}

@media only screen and (max-width: 768px) {
    .ContainerContent {
        flex-direction: column;
        align-items: center;
       }
    .ButtonContent {
        display: flex;
        flex-direction: column;
        width: 50%;
        } 
    .Info {
        margin-top:30px;
        width: 100%;
        margin-left: 0px;
    }

    }

@media only screen and (max-width: 1024px) {
    .ContainerContent {
        flex-direction: column;
        align-items: center;
       }

    .ButtonContent {
        display: flex;
        flex-direction: column;
        width: 50%;
        } 

    .Info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top:30px;
    }
        }


    @media only screen and (max-width: 1366px) {
            #Experience {
                margin-left: 5%;
                margin-right: 5%;
               }
     }
    