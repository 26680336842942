    .HamburgerNav {
    box-sizing: border-box;
    display: none;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  
  }
  
  .Menu {
    position: fixed;
    display: none;
    place-items: center;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background: #0A192F;
    transform: translate(-100%);
  }
  
  .Menu.open {
    transform: translate(0);
  }
  .Menu-items{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .Menu > .Menu-items {
    display: grid;
    place-items: center;
    grid-gap: 20px;
  }
  
  .Menu > .Menu-items > a {
    color: #CCD6f6;
    font-size: clamp(10px, 25px, 40px);
    text-decoration: none;
    position: relative;
  }
  
  .Menu > .Menu-items > a:hover{
    color: #64ffda;
  }
  
   .Menu.open .Menu-items > a:nth-child(1) {
    animation: animate ease-in-out;
    animation-duration: 0.7s;
  }
  
   .Menu.open .Menu-items > a:nth-child(2) {
    animation: animate ease-in-out;
    animation-duration: 0.85s;
  }
  
  .Menu.open .Menu-items > a:nth-child(3) {
    animation: animate ease-in-out;
    animation-duration: 1s;
  }
  
  .Menu.open .Menu-items > a:nth-child(4) {
    animation: animate ease-in-out;
    animation-duration: 1.15s;
  }
  
  .MenuHeader {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  @keyframes animate {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @media only screen and (max-width: 768px) {
    .HamburgerNav {
      display: flex;
      width: 80%;
      margin: 30px auto 30px auto;
      align-items: center;
    }
  
    .Menu {
      display: grid;
    }
  
    .svgButton {
      overflow: hidden;
      width: 30px;
      height: 30px;
      border: none;
      background: none;
      box-shadow: none;
      padding: 0;
    }
    .svgButton > button  {
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
        box-shadow: none;
      }
  
    .MenuHeader {
      display: flex;
      width: 80%;
      margin: 30px auto auto auto;
      height: 50px;
    }
   
    .hamburgerLogo {
        height: 50px;
        width: 50px;
        overflow: hidden;
    }
  }