  .Email {
    position: fixed;
    display: flex;
    bottom: 0;
    right: 60px;
    width: 40px;
    padding: 0;
    margin: 0;
  }
  .Email p {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin:0px;
  }
  
  .Email a {
    transition: all 0.3s ease;
    writing-mode: vertical-rl;
    text-decoration: none;
    color: #CCD6f6;
    margin: 0px;
    margin-bottom: 10px;
  }

  .Email p:after {
    margin-top:15px;
    content: "";
    display: block;
    width: 1px;
    position: relative;
    height: 90px;
    border-left: 1px solid #CCD6f6;
    bottom: 0px;
  }

  .Email a:hover  {
    transform: translateY(-5px);
  }
  .Email a:hover {
    color: #64ffda;
  }

  @media only screen and (max-width: 1366px) {
    .Email {
      display:none;
    }
  }
  