.aboutMe
{
    height: 100%;
    width: 100%;
}
.name {
    display: block;
    margin: 0px 0px 30px 4px;
    color: #64ffda;
    font-family: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;
    font-weight: 400;
    padding-bottom: 10px;
    margin:0px;
}
.NumberedHeader {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: clamp(26px,5vw,32px);
  }
  
  .name {
    position: relative;
    counter-increment: section var(--section-number);
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
    font-size: 32px;
    font-weight: 400;
  }
  
  .name::before {
    content: "0" counter(section) ".";
    color: #64ffda;
    padding-right: 10px;
  }

  