#AboutMe {
    margin-top: 100px;
    height: 100%;
    width: 100%;
}

.InformationHolder {
    display: flex;
    align-items: center;
    flex-direction:row;
    justify-content: center;
    width: 100%;
    gap:50px;
}

.infoAboutMe {
    margin-right: 100px;
}

.infoAboutMe > p {
    width: clamp(200px,500px,700px);
    font-size: 22px;
    color:#8892B0 ;
}

.Technologies {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    gap: 0px 10px;
    padding: 0px;
    margin: 20px 0px 0px;
    overflow: hidden;
    list-style: none;
    
}
.Technologies > li {
    position: relative;
    margin-bottom: 10px;
    padding-left: 20px;
    font-family: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;;
    font-size: clamp(10px, 20px, 25px);
    color: #8892B0;
}

.Technologies > li::before {
    content: "▹";
    position: absolute;
    left: 0px;
    color: #64ffda;
    font-size: 18px;
    line-height: clamp(13px, 20px, 25px);
}

.image > img {
    width: 400px;
    height: auto;
    padding: 0;
    margin: 0;
    border-radius: 50%;
}


@media only screen and (max-width: 320px) {
    .InformationHolder {
        flex-direction: column;
        margin-right: 0px;
        margin-bottom: 30px;
    }
    .infoAboutMe > p {
        width: 100%;
    }
  }

@media only screen and (max-width: 480px) {
    .InformationHolder {
        flex-direction: column;
    }
    .infoAboutMe {
        margin-right: 0px;
        margin-bottom: 30px;
    }
    .infoAboutMe > p {
        width: 100%;
    }
    .Technologies
    {
        grid-template-columns: repeat(1, minmax(140px, 200px));
    }

    .image > img {
        width: 200px !important;
    }
    
    }

@media only screen and (max-width: 768px) {
        .InformationHolder {
            flex-direction: column;
        }
        .infoAboutMe {
            margin-right: 0px;
            margin-bottom: 30px;
        }
        .infoAboutMe > p {
            width: 100%;
        }

        .image > img {
            width: 300px;
        }

        #AboutMe {
            margin-top:50px;
        }
    }

@media only screen and (max-width: 1024px) {
        .InformationHolder {
            flex-direction: column;
        }
        .infoAboutMe {
            margin-right: 0px;
            margin-bottom: 30px;
        }
        .infoAboutMe > p {
            width: 100%;
        }
    }
@media only screen and (max-width: 1366px) {
            .InformationHolder {
                flex-direction: column;
            }
            .infoAboutMe {
                margin-right: 0px;
                margin-bottom: 30px;
            }
            #AboutMe
            {
                display: flex;
                flex-direction: column;
                margin-left: 10%;
                margin-right: 10%;
            }
            .infoAboutMe > p {
                width: 100%;
            }
        }