.social-icons {
  position: fixed;
  display: flex;
  bottom: 0;
  left: 60px;
  width: 40px;
  padding: 0;
  margin: 0;
}
.social-icons ul {
  width: 100%;
  padding: 0;
  margin: 0;
}
.social-icons li {
  list-style: none;
  margin-bottom: 15px;
}

.social-icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color:rgb(10, 25, 47);
  transition: all 0.3s ease;
  margin-bottom: 10px;
}

.social-icons a:hover  {
  transform: translateY(-5px);
}

.social-icons a:hover svg {
  color: #64ffda;
}

.social-icons svg {
  color: #CCD6f6;
  width: 24px;
  height: 24px;
}

.social-icons li:last-child {
  margin-bottom: 0;
}

.social-icons li:last-child:after {
  content: "";
  display: block;
  width: 1px;
  position: relative;
  left: calc(50%);
  height: 90px;
  border-left: 1px solid #CCD6f6;
  bottom: 0;
}

@media only screen and (max-width: 1366px) {
  .social-icons {
    display:none;
  }
}
